import axios from "axios";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import CountUp from "react-countup";
import { Helmet } from "react-helmet";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import ReviewSection from "../../components/review-section";
import TeamSection from "../../components/team-section";
import useOnScreen from "../../components/use-on-screen";

const IndexPageDubai = () => {
	const data = useStaticQuery(graphql`
		query {
			wpSeoPage(title: { eq: "Home Dubai" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}

			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			webDevimg: wpMediaItem(title: { eq: "web-design-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg2: wpMediaItem(title: { eq: "side-image-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			bgImg: wpMediaItem(title: { eq: "home-background-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg1: wpMediaItem(title: { eq: "shopify" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			logoImg3: wpMediaItem(title: { eq: "Gatsby" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg4: wpMediaItem(title: { eq: "Firebase" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg5: wpMediaItem(title: { eq: "Semrush" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg6: wpMediaItem(title: { eq: "ECO_IMAGE_1" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg7: wpMediaItem(title: { eq: "ECO_IMAGE_2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg8: wpMediaItem(title: { eq: "ECO_IMAGE_3" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg9: wpMediaItem(title: { eq: "ECO_IMAGE_4" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seoImg: wpMediaItem(title: { eq: "seo-new" }) {
				altText
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
				link
			}

			gAdsImg: wpMediaItem(title: { eq: "google-ads-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ecommerceImg: wpMediaItem(title: { eq: "e-commerce-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const [trees, setTrees] = useState("");
	const [carbon, setCarbon] = useState("");
	const [months, setMonths] = useState("");
	const [selectedImage, setSelectedImage] = useState(data.webDevimg);

	function monthDiff(dateFrom, dateTo) {
		return (
			dateTo.getMonth() -
			dateFrom.getMonth() +
			12 * (dateTo.getFullYear() - dateFrom.getFullYear())
		);
	}

	useEffect(() => {
		axios
			.get(`https://public.ecologi.com/users/rjmdigital/impact`)
			.then((res) => {
				setTrees(res.data.trees);
				setCarbon(res.data.carbonOffset);
				setMonths(monthDiff(new Date(2021, 10), new Date()));
			});
	}, []);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const ref1 = useRef();
	const onScreen1 = useOnScreen(ref1, "0px");

	const schema = data.wpSeoPage?.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Dubai",
				item: {
					url: `${siteUrl}/dubai`,
					id: `${siteUrl}/dubai`,
				},
			},
		],
	};

	const enterAnimation = (text, img) => {
		if (selectedImage !== img) {
			gsap.to(
				".selected-image",

				{ opacity: 0, duration: 0.5 }
			);
			gsap.to(
				".selected-image",

				{ opacity: 1, duration: 1, delay: 0.6 }
			);
			setTimeout(function () {
				setSelectedImage(img);
			}, 500);
		}

		gsap.to(
			text,

			{ opacity: 1, color: "#FFCA2C", duration: 0.5 }
		);
	};
	const exitAnimation = (text) => {
		gsap.to(
			text,

			{ opacity: 0.69, color: "white", duration: 0.5 }
		);
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>

			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/dubai`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout dubai>
				<section>
					<Hero
						dubai
						button1="primary-link-dark"
						button1Text="Book a discovery meeting"
						button1Link="#form"
						button1Var="white"
						button2="white-link-yellow"
						button2Text="What we do"
						button2Link="/what-we-do"
						button2Var="dark-blue"
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`Achieve \n<span class="text-secondary">More.</span>`}
						description="A British-run digital marketing agency to take your Dubai business further."
					/>
				</section>
				<section className="bg-light-grey py-5 py-lg-8">
					<Container>
						<Row className="justify-content-center">
							<Col lg={8} xl={6} className="text-center">
								<p className="ssp-bold text-uppercase text-light-blue">
									DEVELOP YOUR BUSINESS
								</p>
								<h2 className="display-5 text-primary mb-5">
									A digital marketing
									<br className="d-none d-lg-block" /> agency for companies
									<br className="d-none d-lg-block" /> with ambition
								</h2>
								<p>
									Creating an effective digital marketing strategy allows you to
									promote your Dubai business to anyone, anywhere, at any time.
								</p>

								<p>
									RJM Digital will work with you to implement a new digital
									strategy or optimise a pre-existing one. We provide a variety
									of services and solutions specifically designed for Dubai
									businesses that will make you successful online. Ultimately,
									helping your business attract more of your target customers,
									generate exciting leads which will increase your revenue like
									never before.
								</p>

								<div className="d-md-flex text-center mt-5 align-items-center justify-content-center">
									<Button
										className=" cta-btn fs-5 w-100 w-md-auto me-md-4 py-2 px-4 white-link"
										variant="primary"
										as={Link}
										to="#form"
									>
										Book a discovery call
									</Button>
									<Link
										className="primary-link ssp-bold fs-5 pt-4 pt-md-0 pb-0 mb-0 d-inline-block"
										to="/dubai/contact-us"
									>
										Send an enquiry
									</Link>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section
					className="py-5 py-lg-7 "
					style={{
						background:
							"transparent linear-gradient(52deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
				>
					<Container>
						<Row className="align-items-center">
							<Col className="text-center d-lg-none" lg={6}>
								<Link
									to="/dubai/web-design"
									className="display-3 ssp-bold d-block text-decoration-none mb-4 mb-lg-6 white-link grow-hover-1"
								>
									WEB DESIGN
								</Link>

								<Link
									to="/dubai/seo"
									className="display-3 mb-4 mb-lg-6 ssp-bold d-block text-decoration-none white-link grow-hover-3"
								>
									SEO
								</Link>
								<Link
									to="/dubai/google-ads"
									className="display-3 mb-4 mb-lg-6 ssp-bold d-block text-decoration-none white-link grow-hover-4"
								>
									GOOGLE ADS
								</Link>
								<Link
									to="/e-commerce-websites"
									className="display-3 mb-4 mb-lg-6 ssp-bold d-block text-decoration-none white-link grow-hover-5"
								>
									E-COMMERCE
								</Link>
							</Col>
							<Col className="text-start d-none d-lg-block" lg={6}>
								<Link
									to="/dubai/web-design"
									onMouseEnter={() =>
										enterAnimation(".grow-hover-1", data.webDevimg)
									}
									onMouseLeave={() => exitAnimation(".grow-hover-1")}
									className="display-3 ssp-bold d-block text-decoration-none mb-4 mb-lg-6 grow-hover-1"
								>
									WEB DESIGN
								</Link>

								<Link
									to="/dubai/seo"
									onMouseEnter={() =>
										enterAnimation(".grow-hover-3", data.seoImg)
									}
									onMouseLeave={() => exitAnimation(".grow-hover-3")}
									className="display-3 mb-4 mb-lg-6 ssp-bold d-block text-decoration-none grow-hover-3"
								>
									SEO
								</Link>
								<Link
									to="/dubai/google-ads"
									onMouseEnter={() =>
										enterAnimation(".grow-hover-4", data.gAdsImg)
									}
									onMouseLeave={() => exitAnimation(".grow-hover-4")}
									className="display-3 mb-4 mb-lg-6 ssp-bold d-block text-decoration-none grow-hover-4"
								>
									GOOGLE ADS
								</Link>
								<Link
									to="/e-commerce-websites"
									onMouseEnter={() =>
										enterAnimation(".grow-hover-5", data.ecommerceImg)
									}
									onMouseLeave={() => exitAnimation(".grow-hover-5")}
									className="display-3 mb-4 mb-lg-6 ssp-bold d-block text-decoration-none grow-hover-5"
								>
									E-COMMERCE
								</Link>
							</Col>
							<Col className="d-none d-lg-block">
								<div
									className="   w-100 "
									style={{
										overflow: "hidden",
									}}
								>
									<GatsbyImage
										image={selectedImage.gatsbyImage}
										alt={selectedImage?.altText}
										className="w-90 selected-image"
										objectPosition="top center"
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <Logos />
				<section className="py-5 py-lg-7 bg-light-grey">
					<PortfolioSection page="Home" />
				</section> */}
				<section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection page="Home" />
				</section>
				<section className="vh-100 d-none d-xl-block ">
					<GatsbyImage
						className="vh-100 position-fixed w-100"
						style={{ zIndex: "-9999", top: "0" }}
						image={data.bgImg.gatsbyImage}
						alt=""
					/>
				</section>
				<section className="py-5 pt-lg-7 bg-light-grey">
					<Container>
						<Row className="justify-content-between align-items-lg-center align-items-xl-start">
							<Col className=" pt-5 pb-5 pb-lg-7 mb-xl-5" lg={6}>
								{/* <div
									className=" d-lg-none w-100 mb-5 "
									style={{
										borderRadius: "15px",
										overflow: "hidden",

										boxShadow: "0px 3px 70px #00000086",
									}}
								>
									<GatsbyImage
										image={
											data.sideImg1.gatsbyImage
										}
										alt=""
									/>
								</div> */}
								<h2 className="display-5 pb-5 text-primary">
									How you will achieve more with RJM Digital
								</h2>
								<p>
									A successful marketing strategy isn't just about good ideas,
									it's about producing real results. We provide digital
									marketing services that are bespoke to your business which
									will enable you to stand out from your competitors and
									generate quality leads.
								</p>

								<p>
									Our team are specialists in branding, web design, search
									engine optimisation and Google Ads, all are essential to help
									take your business to the next level.
								</p>

								<p>
									We will continually review and optimise our recommended
									approach and will regularly report back to you about the
									results that you have achieved and explore new ideas on how to
									improve.
								</p>

								<Button
									className=" cta-btn w-100 w-md-auto fs-5 white-link-yellow py-2 px-4 mt-4 "
									variant="primary"
									as={Link}
									to="/dubai/contact-us"
								>
									Get in touch with RJM Digital
								</Button>
							</Col>
							<Col className="position-relative" xl={4} lg={5}>
								{/* <div
									className=" d-none d-lg-block d-xl-none w-100 mb-5 "
									style={{
										borderRadius: "15px",
										overflow: "hidden",

										boxShadow: "0px 3px 70px #00000086",
									}}
								>
									<GatsbyImage
										image={
											data.sideImg1.gatsbyImage
										}
										alt=""
									/>
								</div> */}
								<div
									className="  d-none d-lg-block d-xl-none w-100 mb-5 "
									style={{
										borderRadius: "15px",
										overflow: "hidden",

										boxShadow: "0px 3px 70px #00000086",
									}}
								>
									<GatsbyImage image={data.sideImg2.gatsbyImage} alt="" />
								</div>
								{/* <div
									className=" d-none d-xl-block w-100  end-0 position-absolute "
									style={{
										borderRadius: "10px",
										overflow: "hidden",
										top: "-1.5rem",
										boxShadow: "0px 3px 90px #00000043",
									}}
								>
									<GatsbyImage
										image={
											data.sideImg1.gatsbyImage
										}
										alt=""
									/>
								</div> */}

								<div
									className=" d-none d-xl-block position-absolute w-100 "
									style={{
										borderRadius: "10px",
										overflow: "hidden",
										left: "-7rem",
										top: "13rem",
										boxShadow: "0px 3px 90px #00000043",
									}}
								>
									<GatsbyImage image={data.sideImg2.gatsbyImage} alt="" />
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pb-5 pb-lg-7  bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<h2 className="display-5 text-primary mb-3">
									Book a discovery meeting
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar to discuss your project.
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<TeamSection />
				</section>
				<section
					style={{
						background:
							"transparent linear-gradient(180deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className="py-5 py-lg-7"
				>
					<Container>
						<Row className="justify-content-center">
							<Col xs={6} lg={true}>
								<GatsbyImage
									image={data.logoImg1.gatsbyImage}
									alt={data.logoImg1?.altText}
									className="small-max-width"
								/>
							</Col>
							<Col xs={6} className="d-flex align-items-center" lg={true}>
								<StaticImage
									src="../../images/Wordpress-Final.png"
									placeholder="blurred"
									quality={100}
									className="small-max-width"
								/>
							</Col>
							<Col xs={6} lg={true}>
								<GatsbyImage
									image={data.logoImg3.gatsbyImage}
									alt={data.logoImg3?.altText}
									className="small-max-width"
								/>
							</Col>
							<Col xs={6} lg={true}>
								<GatsbyImage
									image={data.logoImg4.gatsbyImage}
									alt={data.logoImg4?.altText}
									className="small-max-width"
								/>
							</Col>
							<Col xs={6} lg={true}>
								<GatsbyImage
									image={data.logoImg5.gatsbyImage}
									alt={data.logoImg5?.altText}
									className="small-max-width"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<section
					style={{ overflow: "hidden" }}
					className="py-5 pb-lg-7 pt-lg-9 position-relative bg-dark-bg "
				>
					<div
						style={{ height: "1rem", backgroundColor: "#27676A", zIndex: 2 }}
						className="w-100 position-absolute bottom-0"
					></div>
					<StaticImage
						className=" start-0 position-absolute"
						style={{
							zIndex: 1,
							width: "100%",
							maxHeight: "100%",
							bottom: "0.5rem",
						}}
						src="../../images/Sustainble-background.svg"
						placeholder="blurred"
						quality={100}
					/>
					<Container style={{ zIndex: 3 }} className="position-relative">
						<Row className="justify-content-center">
							<Col lg={8} xl={7} className="text-center pb-5">
								{" "}
								<h2 className="display-5 pb-5 text-uppercase text-white">
									Sustainability
								</h2>
								<p className="text-white text-start text-md-center pb-5">
									Achieve your sustainability goals by creating an eco-friendly
									and energy-efficient website. Whether you are looking for RJM
									Digital to build you a brand new website or to convert an
									existing one, we can help you align your website to your
									sustainability values.
								</p>
								<Button
									className=" cta-btn w-100 w-md-auto fs-5 primary-link-dark py-2 px-4 "
									variant="white"
									as={Link}
									to="/dubai/sustainable-web-design"
								>
									Build your sustainable website
								</Button>
							</Col>
						</Row>
						<Row>
							<Col>
								<h3 className="display-5 pb-5 text-center text-white">
									Our partnerships
								</h3>
							</Col>
						</Row>
						<Row className="gy-5 justify-content-center">
							<Col className="text-center" xs={6} md={3}>
								<div
									style={{
										borderRadius: "30px",
										overflow: "hidden",
										width: "110px",
										height: "110px",
									}}
									className="bg-white d-flex justify-content-center mx-auto align-items-center"
								>
									<GatsbyImage
										style={{ maxWidth: "90px" }}
										image={data.logoImg6.gatsbyImage}
										alt={data.logoImg6?.altText}
									/>
								</div>
							</Col>
							<Col className="text-center" xs={6} md={3}>
								<div
									style={{
										borderRadius: "30px",
										overflow: "hidden",
										width: "110px",
										height: "110px",
									}}
									className="bg-white d-flex justify-content-center mx-auto align-items-center"
								>
									<GatsbyImage
										style={{ maxWidth: "90px" }}
										image={data.logoImg7.gatsbyImage}
										alt={data.logoImg7?.altText}
									/>
								</div>
							</Col>
							{/* <Col className="text-center" xs={6} md={3}>
								<div
									style={{
										borderRadius: "30px",
										overflow: "hidden",
										width: "110px",
										height: "110px",
									}}
									className="bg-white d-flex justify-content-center mx-auto align-items-center"
								>
									<GatsbyImage
										style={{ maxWidth: "90px" }}
										image={
											data.logoImg8.gatsbyImage
										}
										alt={data.logoImg8?.altText}
									/>
								</div>
							</Col>
							<Col className="text-center" xs={6} md={3}>
								<div
									style={{
										borderRadius: "30px",
										overflow: "hidden",
										width: "110px",
										height: "110px",
									}}
									className="bg-white d-flex justify-content-center mx-auto align-items-center"
								>
									<GatsbyImage
										style={{ maxWidth: "90px" }}
										image={
											data.logoImg9.gatsbyImage
										}
										alt={data.logoImg9?.altText}
									/>
								</div>
							</Col> */}
						</Row>
					</Container>
				</section>
				<section
					style={{
						background:
							"transparent linear-gradient(180deg, #27676A 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className="py-5  py-lg-7 pb-lg-9"
				>
					<Container>
						<Row>
							<Col>
								<h2 className="display-5 pb-5 text-white">Our impact</h2>
							</Col>
						</Row>
						<Row className="d-none d-md-flex" ref={ref1}>
							<Col className="text-center" xs={6} lg={4}>
								<div
									style={{ height: "190px" }}
									className="d-flex align-items-center justify-content-center"
								>
									<StaticImage
										src="../../images/ECO 1 - SVG.svg"
										placeholder="blurred"
										style={{ maxWidth: "200px" }}
										quality={100}
										className="mb-4"
									/>
								</div>
								<h3 className="text-white fs-1">
									{onScreen1 && <CountUp end={months} />} months
								</h3>
								<p className="text-white">Climate impact</p>
							</Col>
							<Col className="text-center pb-5 pb-lg-0" xs={6} lg={4}>
								<div
									style={{ height: "190px" }}
									className="d-flex align-items-center justify-content-center"
								>
									<StaticImage
										src="../../images/ECO 2 - svg.svg"
										placeholder="blurred"
										style={{ maxWidth: "200px" }}
										quality={100}
										className="mb-4"
									/>
								</div>
								<h3 className="text-white fs-1">
									{onScreen1 && <CountUp decimals={2} end={carbon} />}t
								</h3>
								<p className="text-white">Carbon reduction</p>
							</Col>
							<Col className="text-center" lg={4}>
								<div
									style={{ height: "190px" }}
									className="d-flex align-items-center justify-content-center"
								>
									<StaticImage
										src="../../images/ECO 3 - svg.svg"
										placeholder="blurred"
										style={{ maxWidth: "200px" }}
										quality={100}
										className="mb-4"
									/>
								</div>
								<h3 className="text-white fs-1">
									{onScreen1 && <CountUp end={trees} />}
								</h3>
								<p className="text-white">Trees in our forest</p>
							</Col>
						</Row>
						<Row className="d-md-none">
							<Col>
								<Carousel interval={3000}>
									<Carousel.Item>
										<div className="pb-5">
											<div className="text-center" style={{ height: "200px" }}>
												<StaticImage
													src="../../images/ECO 1 - SVG.svg"
													placeholder="blurred"
													style={{ maxWidth: "200px" }}
													quality={100}
													className="mb-4"
												/>
											</div>
											<div className="text-center">
												<h3 className="text-white fs-1">{months} months</h3>
												<p className="text-white">Climate impact</p>
											</div>
										</div>
									</Carousel.Item>
									<Carousel.Item>
										<div className="pb-5 text-center">
											<div className="text-center" style={{ height: "200px" }}>
												<StaticImage
													src="../../images/ECO 2 - svg.svg"
													placeholder="blurred"
													style={{ maxWidth: "200px" }}
													quality={100}
													className="mb-4"
												/>
											</div>
											<h3 className="text-white fs-1">{carbon}t</h3>
											<p className="text-white">Carbon reduction</p>
										</div>
									</Carousel.Item>
									<Carousel.Item>
										<div className="pb-5 text-center">
											<div className="text-center" style={{ height: "200px" }}>
												<StaticImage
													src="../../images/ECO 3 - svg.svg"
													placeholder="blurred"
													style={{ maxWidth: "200px" }}
													quality={100}
													className="mb-4"
												/>
											</div>
											<h3 className="text-white fs-1">{trees}</h3>
											<p className="text-white">Trees in our forest</p>
										</div>
									</Carousel.Item>
								</Carousel>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default IndexPageDubai;
